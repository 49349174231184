
import IsBeAuth from '../../components/IsBeAuth.vue'
import AppBar from './components/AppBar/AppBar.vue'
import ContentsArea from '../Layout/components/ContentsArea.vue'
import FileBoxCapacitySkelton from '../FileBoxCapacity/components/FileBoxCapacitySkelton.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IsBeAuth,
    AppBar,
    ContentsArea,
    FileBoxCapacitySkelton
  }
})
export default class Layout extends Vue {
  login: boolean = false
  mode: string = process.env.VUE_APP_MODE
  afterLogin(event: boolean) {
    console.log(event)
    this.login = event
  }
}
