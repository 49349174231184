import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Layout from '../views/Layout/Layout.vue'
import FileBoxCapacity from '@/views/FileBoxCapacity/FileBoxCapacity.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "",
    name: "",
    component: Layout,
    children: [
      {
        path: '/FileBoxCapacity',
        name: 'FileBoxCapacity',
        component: FileBoxCapacity
      }
    ]
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
