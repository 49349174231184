export const USED_APP_TABLE_HEADER = [
  {
    text: '項目名',
    sortable: false,
    value: 'systemName',
    width: '20%',
    align: 'center'
  },
  {
    text: '取引先コード',
    sortable: false,
    value: 'supplierCd',
    width: '20%',
    align: 'center'
  },
  {
    text: 'ファイル容量',
    sortable: false,
    value: 'fileboxsize',
    width: '20%',
    align: 'center'
  },
  {
    text: 'メインサーバー内保持件数',
    sortable: false,
    value: 'dataSize',
    width: '20%',
    align: 'center'
  },
  {
    text: '',
    sortable: false,
    value: 'arrowArea',
    align: 'center',
    width: '20px'
  },
  {
    text: 'アーカイブサーバー内保持件数',
    sortable: false,
    value: 'archiveSize',
    width: '20%',
    align: 'center'
  }
]


export const USED_ACCOUNT_TABLE_HEADER = [
  {
    text: '項目名',
    sortable: false,
    value: 'name',
    width: '20%',
    align: 'center'
  },
  {
    text: '取引先コード',
    sortable: false,
    value: 'supplierCd',
    width: '20%',
    align: 'center'
  },
  {
    text: '利用人数',
    sortable: false,
    width: '20%',
    value: 'users',
    align: 'center'
  },
  {
    text: '',
    sortable: false,
    value: '',
    align: 'center',
    width: '20%'
  },
  {
    text: '',
    sortable: false,
    value: '',
    align: 'center',
    width: '20%'
  }
]

export const USED_APPUSAGE_TABLE_HEADER = [
  {
    text: '項目名',
    sortable: false,
    value: 'name',
    width: '20%',
    align: 'center'
  },
  {
    text: '取引先コード',
    sortable: false,
    value: 'supplierCd',
    width: '20%',
    align: 'center'
  },
  {
    text: '利用状況/契約内容',
    sortable: false,
    value: 'usage',
    width: '20%',
    align: 'center'
  },
  {
    text: '',
    sortable: false,
    value: '',
    width: '20%',
    align: 'center'
  },
  {
    text: '',
    sortable: false,
    value: '',
    width: '20%',
    align: 'center'
  }
]