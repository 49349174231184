import Component from 'vue-class-component'
import FileBoxCapacityMixin from './mixin/FileBoxCapacityMixin'
import { Mixins } from 'vue-property-decorator'
import UsageConfirmation from './components/UsageConfirmation/UsageConfirmation.vue'
import AppUsageComfirmation from './components/AppUsageComfirmation/AppUsageComfirmation.vue'

@Component({
  components: { UsageConfirmation, AppUsageComfirmation }
})
export default class FileBoxCapatity extends Mixins(FileBoxCapacityMixin) {


}