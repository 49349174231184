import http from './http-common'
import store from '@/store';
import { Charge } from '@/interfaces/charge';
class IsBizApiService {
  getCharge(garageId: string): Promise<Charge[]> {
    const headers = {
      "Content-type": "application/json",
      "Authorization": `Bearer ${store.state.token}`
    }
    return http.isBizapiClient.get(`/api/charge?garageId=${garageId}`, { headers: headers });
  }


}

export default new IsBizApiService();