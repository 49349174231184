import Component from 'vue-class-component'
import store from '@/store'
import { ApplicationUsage, Charge, Usage, VuetifyTableHeader } from '@/interfaces/charge'

import { Mixins } from 'vue-property-decorator'
import FileBoxCapacityMixin from '../../mixin/FileBoxCapacityMixin'
import { USED_APP_TABLE_HEADER } from '../../Static/headerdatas'

@Component
export default class AppUsageComfirmation extends Mixins(FileBoxCapacityMixin) {
  headers: VuetifyTableHeader[] = USED_APP_TABLE_HEADER
  desserts: ApplicationUsage[] = []
  archiveContracted: boolean = false
  created() {
    if (store.state.charge) {
      const { applicationUsage, archiveContracted } = store.state.charge
      this.desserts = applicationUsage
      this.archiveContracted = archiveContracted
    }
  }



}