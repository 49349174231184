import axios, { AxiosInstance } from "axios";

const isBizapiClient: AxiosInstance = axios.create({
  // APIのURI
  baseURL: process.env.VUE_APP_ISBIZ_API_URL,
  // リクエストヘッダ
  headers: {
    "Content-type": "application/json",
  },
});

export default { isBizapiClient };