import Component from 'vue-class-component'
import store from '@/store'
import { ApplicationUsage, Charge, Usage, VuetifyTableHeader } from '@/interfaces/charge'

import { Mixins } from 'vue-property-decorator'
import FileBoxCapacityMixin from '../../mixin/FileBoxCapacityMixin'
import { USED_APPUSAGE_TABLE_HEADER } from '../../Static/headerdatas'

@Component
export default class UsageConfirmation extends Mixins(FileBoxCapacityMixin) {
  headersUsage: VuetifyTableHeader[] = USED_APPUSAGE_TABLE_HEADER
  dessertsUsage: Usage[] = []
  created() {
    if (store.state.charge) {
      this.SetCharge()
    }
  }

  /**
   * 利用状況取得
   */
  async SetCharge() {
    if (store.state.charge) this.converAccountUsage(store.state.charge)
  }
  converAccountUsage(data: Charge) {
    let accountData = {
      name: 'Account',
      used: data.accountTotalSize,
      limit: data.accountMaxSize
    }
    this.dessertsUsage.push(accountData)
    this.UsageDataCalculation(data)
  }
  UsageDataCalculation(data: Charge) {
    const { fileboxMaxSize, fileboxTotalSize, databoxMaxSize, databoxTotalSize, archiveMaxSize, archiveTotalSize } =
      data

    /**
     * fileboxMaxSizeはGBでくるのでbyteに変換をする。
     */
    const fileboxMaxSizebyte = this.convertGigabytetoByte(fileboxMaxSize)
    this.dessertsUsage.push(
      {
        name: 'FileBox',
        used: fileboxTotalSize,
        limit: fileboxMaxSizebyte
      },
      {
        name: 'DataBox(メイン)',
        used: databoxTotalSize,
        limit: databoxMaxSize
      },
      {
        name: 'DataBox(アーカイブ)',
        used: archiveTotalSize,
        limit: archiveMaxSize
      }
    )
  }

  /**
   * ギガバイトをバイトに変換する。
   * @param gigabyte 
   * @returns 
   */
  convertGigabytetoByte(gigabyte: number): number {
    return gigabyte * 1024 * 1024 * 1024
  }
}