import { Vue, Component } from 'vue-property-decorator'

@Component({
  filters: {
    numberWithDelimiter(value: number): string {
      if (!value) return '0'
      if (!!(value % 1)) {
        let splotval = value.toFixed(2).split('.')

        let retVal = Number(splotval[0]).toLocaleString() + '.' + splotval[1]
        return retVal
      }
      return value.toLocaleString()
    }
  }
})
export default class FileBoxCapacityMixin extends Vue {
  reDirectButton() {
    location.href = process.env.VUE_APP_GARAGE_SUPPORT_GOOGLEFORM_URL
  }
  parCalculation(min: number, max: number) {
    return (min / max) * 100
  }
  //容量をbyteからGBに変換
  byteSizeCalculation(byte: number) {
    // 2の30条で割る 四捨五入
    return byte / 1073741824
  }
  //容量をGBからbyteに変換
  gigaByteSizeCalculation(gigaByte: number) {
    // 2の30条で割る 四捨五入
    return gigaByte * 1073741824
  }
  getArchive(systemKey: string) {
    // return systemKey === 'garage_sm_item' ||
    //   systemKey === 'garage_sm_aqua' ||
    //   systemKey === 'garage_product_info' ||
    //   systemKey === 'garage_sm_proposal' || systemKey === 'garage_sm_sales_support'
    return systemKey === 'garage_sm_item'
  }
}