export const data = {
    "resultCode": 0,
    "resultMessage": "正常終了",
    "data": {
        "garageId": "b7dec663-665b-49ad-b849-fef7469b09e0",
        "actuateName": "丸眞株式会社",
        "applicationUsage": [
            {
                "systemKey": "garage_product_info",
                "systemName": "商品情報 LEAF",
                "supplierCd": "",
                "fileboxUsedSize": 266638982,
                "databoxUsedSize": 2780,
                "archiveUsedSize": 0
            },
            {
                "systemKey": "garage_sm_item",
                "systemName": "しまむらアイテム情報 LEAF",
                "supplierCd": "",
                "fileboxUsedSize": 443048620,
                "databoxUsedSize": 5072,
                "archiveUsedSize": 1356
            },
            {
                "systemKey": "garage_sm_aqua",
                "systemName": "しまむらPB検査申請 LEAF",
                "supplierCd": "",
                "fileboxUsedSize": 12512151,
                "databoxUsedSize": 492,
                "archiveUsedSize": 0
            },
            {
                "systemKey": "garage_sm_proposal",
                "systemName": "しまむら提案 LEAF",
                "supplierCd": "",
                "fileboxUsedSize": 269885781,
                "databoxUsedSize": 2624,
                "archiveUsedSize": 0
            },
            {
                "systemKey": "garage_sm_sales_support",
                "systemName": "しまむら商談支援 LEAF",
                "supplierCd": "",
                "fileboxUsedSize": 31907557,
                "databoxUsedSize": 0,
                "archiveUsedSize": 0
            }
        ],
        "accountTotalSize": 5,
        "accountMaxSize": 5,
        "fileboxTotalSize": 1023993091,
        "fileboxMaxSize": 1,
        "databoxTotalSize": 10968,
        "databoxMaxSize": 2000,
        "archiveTotalSize": 1356,
        "archiveMaxSize": 10000,
        "lastMonthCharge": {
            "fileboxChargeAmount": null,
            "databoxMainChargeAmount": null,
            "databoxArchiveChargeAmount": null
        },
        "currentMonthCharge": {
            "fileboxChargeAmount": null,
            "databoxMainChargeAmount": null,
            "databoxArchiveChargeAmount": null
        },
        "archiveContracted": true
    }
}