
import store from '@/store'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import isBizApiService from '@/apis/IsBizApiService'
import { data } from './charge'
declare const window: {
  location: { origin: string }
  BeLoginCore: { login: () => void }
}
@Component({
  components: {}
})
export default class IsBeAuth extends Vue {
  @Prop({ default: false }) public login!: boolean

  @Prop({ default: false }) publish!: boolean

  @Emit('after-login')
  afterLogin() {
    return true
  }

  loginUrl: string = process.env.VUE_APP_LOGIN_UI_URL
  baseUrl: string = window.location.origin
  core: any = ''
  created() {
    console.log('IsBeAuthクリエイト・・・')
    const isbeLoginScriptPath = `${this.loginUrl}wwwroot/js/be-login-core.js`
    const isbeLoginScriptElem = document.createElement('script')
    isbeLoginScriptElem.setAttribute('src', isbeLoginScriptPath)
    document.head.appendChild(isbeLoginScriptElem)
    isbeLoginScriptElem.onload = () => {
      this.baseUrl = window.location.origin
      this.core = window.BeLoginCore
      this.core.initialize(this)
      isbeLoginScriptElem.onload = null
    }
  }
  onEndLogin = async (account: string): Promise<void> => {
    await store.dispatch('Initialize', account)
    await this.getCharge()
    this.login = true
    this.afterLogin()
  }
  async getCharge(): Promise<void> {
    const { garageId } = this.$route.query
    if (typeof garageId === 'string') {
      const data = await isBizApiService.getCharge(garageId)
      await store.dispatch('SetCharge', data.data.data)

      // const data = await this.getTestCharge()
      // await store.dispatch('SetCharge', data.data)
    }
  }
  async getTestCharge() {
    console.log(data)

    return data
  }
}
