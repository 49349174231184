import { State } from '@/interfaces/charge';
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store<State>({
  state: {
    accountInfo: "",
    token: '',
    fullname: '',
    charge: undefined
  },
  getters: {
  },
  mutations: {
    SET_ACCOUNTINFO: (state, accountInfo) => {
      state.accountInfo = accountInfo;
    },
    SET_TOKEN: (state, token: string) => {
      state.token = token
    },
    SET_FULLNAME: (state, accountInfo) => {
      state.fullname = accountInfo.FamilyName || '' + accountInfo.FirstName || ''
    },
    SET_CHARGE: (state, charge) => {
      state.charge = charge
    }
  },
  actions: {
    Initialize({ commit }, accountInfo) {
      return new Promise(resolve => {
        const accountInfoJSON = JSON.parse(accountInfo)
        commit("SET_ACCOUNTINFO", accountInfo);
        commit("SET_TOKEN", accountInfoJSON.Token);
        commit("SET_FULLNAME", accountInfoJSON);
        resolve(accountInfo);
      });
    },
    async SetCharge({ commit }, charge) {
      commit("SET_CHARGE", charge);
      return charge
    }

  },
  modules: {
  }
})
