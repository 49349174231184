var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-col',{staticStyle:{"margin-top":"64px"},attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"heading","types":{ heading: 'heading' }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table-thead,table-tbody","types":{
            'table-thead': 'heading@5',
            'table-tbody': 'table-row-divider@4',
            'table-row': 'table-cell@5'
          }}})],1)],1),_c('v-col',{staticStyle:{"margin-top":"64px"},attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"heading","types":{ heading: 'heading' }}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table-thead,table-tbody","types":{
              'table-thead': 'heading@5',
              'table-tbody': 'table-row-divider@4',
              'table-row': 'table-cell@5'
            }}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }